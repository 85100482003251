import Oyster from '../enemies/Oyster';
import MariaFarinha from '../enemies/MariaFarinha';
import BlowFish from '../enemies/BlowFish';

type EnemyType = {
  Component: any;
  position: number;
};

const enemies: EnemyType[] = [
  { Component: MariaFarinha, position: 850 },
  { Component: BlowFish, position: 1200 },
  { Component: Oyster, position: 1800 },
  { Component: MariaFarinha, position: 2350 },
  { Component: BlowFish, position: 3000 },
  { Component: Oyster, position: 4000 },
  { Component: MariaFarinha, position: 4500 },
  { Component: BlowFish, position: 5000 },
  { Component: MariaFarinha, position: 5800 },
];

export default enemies;
