import styled from 'styled-components';
import { Characters } from '../types';
import React from 'react';

import selectDebora from '../assets/select-debora.png';
import selectJoao from '../assets/select-joao.png';
import heart from '../assets/heart.png';
import Ring from '../rings/Ring';
import SpecialThing from '../rings/SpecialThing';

export const Frame = styled.img`
  height: 70px;
  margin: 5px 0;
  z-index: 100;
  image-rendering: pixelated;
`;

const CharacterFrameContainer = styled.div`
  display: flex;
  align-items: center;

  color: white;
  cursor: pointer;
`;

const CharacterInfo = styled.div`
  display: flex;
  margin-left: 8px;
  flex-direction: column;
`;

const CharacterName = styled.div`
  display: flex;

  font-size: 28px;
  text-shadow: 1px -1px 0 rgba(45, 45, 45, 0.6);

  margin: -4px 0 5px;
  text-rendering: optimizeLegibility;
`;

const CharacterHealth = styled.div`
  display: flex;
`;

const Heart = styled.img`
  height: 22px;
  image-rendering: pixelated;
  margin-right: 3px;
`;

const CharacterFrameSuperContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`;

const SpecialThingContainer = styled.div`
  display: flex;
  align-items: center;
  width: 70px;
  justify-content: flex-start;
`;

const RingsCollected = styled.div`
  margin-left: 8px;
`;

type CharacterFrameProps = {
  character: Characters;
  onClick?: (character: Characters) => void;
  lives?: number;
  ringsCollected?: number[];
  specialThingsCollected?: number[];
};

export default ({ character, onClick, lives, ringsCollected, specialThingsCollected }: CharacterFrameProps) => {
  const image = character === Characters.DEBORA ? selectDebora : selectJoao;
  const characterName = character === Characters.DEBORA ? 'DÉBORA' : 'JOÃO';
  const hearts = lives && lives > 0 ? new Array(lives).fill(heart) : [];

  return (
    <CharacterFrameSuperContainer>
      <CharacterFrameContainer onClick={() => onClick && onClick(character)}>
        <Frame src={image} />
        <CharacterInfo>
          <CharacterName>
            <span>{characterName}</span>
            {ringsCollected ? (
              <RingsCollected>
                {ringsCollected.map((index, idx) => (
                  <Ring key={idx} small={true} />
                ))}
              </RingsCollected>
            ) : null}
          </CharacterName>
          {hearts && hearts.length ? (
            <CharacterHealth>
              {hearts.map((heart: any, idx: number) => (
                <Heart src={heart} key={idx} alt="heart" />
              ))}
            </CharacterHealth>
          ) : null}
        </CharacterInfo>
      </CharacterFrameContainer>
      <SpecialThingContainer>
        {specialThingsCollected && specialThingsCollected?.length > 0 && <SpecialThing small={true} type={'tie'} />}
        <div style={{ width: '5px' }}></div>
        {specialThingsCollected && specialThingsCollected?.length > 1 && <SpecialThing small={true} type={'flowers'} />}
      </SpecialThingContainer>
    </CharacterFrameSuperContainer>
  );
};
