import React from 'react';
import styled from 'styled-components';

import { SlowDefaultLayer } from './DefaultLayer';

import bridge from '../../assets/bridge-scenario.png';
import island from '../../assets/island.png';

const Bridge = styled.img`
  position: absolute;
  left: 0;

  bottom: 30px;
  height: 170px;
`;

const Island = styled.img`
  position: absolute;
  left: 2800px;
  bottom: 51px;
  height: 70px;
`;

const SlowScenes: any = styled(SlowDefaultLayer)`
  left: 0;
  bottom: 112px;

  z-index: 4;
  animation-play-state: ${({ playState }: any) => playState};
`;

type ScenesLayerProps = {
  playState: string;
};

export default ({ playState }: ScenesLayerProps) => (
  <SlowScenes playState={playState}>
    <Bridge src={bridge} />
    <Island src={island} />
  </SlowScenes>
);
