export type SpecialThingType = {
  position: number;
  type: string;
};

const allSpecialThings: SpecialThingType[] = [
  { position: 2650, type: 'tie' },
  { position: 3700, type: 'flowers' },
];

export default allSpecialThings;
