import React from 'react';
import styled from 'styled-components';
import joaoSprites from '../assets/joao-sprites.png';
import properties from '../engine/properties';

const JoaoDefault = styled.div`
  width: ${properties.spritesSizeSquarePx * 0.75}px;
  height: ${properties.spritesSizeSquarePx * 1.5}px;

  image-rendering: pixelated;
  background: url(${joaoSprites});
  background-size: ${properties.spritesSizeSquarePx * 3}px ${properties.spritesSizeSquarePx * 3}px;
`;

const JoaoWalk: any = styled(JoaoDefault)`
  animation: animate-joao-walk steps(2) 400ms infinite;
  @keyframes animate-joao-walk {
    from {
      background-position: ${properties.spritesSizeSquarePx * 4.5}px 0;
    }
    to {
      background-position: ${properties.spritesSizeSquarePx * 6}px 0;
    }
  }

  animation-play-state: ${({ playState }: any) => playState};
`;

const JoaoCry = styled(JoaoDefault)`
  animation: animate-joao-cry steps(2) 700ms infinite;
  @keyframes animate-joao-cry {
    from {
      background-position: 0 -${properties.spritesSizeSquarePx * 1.5}px;
    }
    to {
      background-position: -${properties.spritesSizeSquarePx * 1.5}px -${properties.spritesSizeSquarePx * 1.5}px;
    }
  }
`;

const JoaoJump = styled(JoaoDefault)`
  background-position: ${properties.spritesSizeSquarePx * 0.75}px 0;
`;

const JoaoStill = styled(JoaoDefault)`
  background-position: 0 0;
`;

const JoaoHit = styled(JoaoDefault)`
  animation: animate-joao-hit steps(2) 200ms infinite;
  @keyframes animate-joao-hit {
    from {
      background-position: 0 -${properties.spritesSizeSquarePx * 1.5}px;
    }
    to {
      background-position: ${properties.spritesSizeSquarePx * 1.5}px -${properties.spritesSizeSquarePx * 1.5}px;
    }
  }

  animation-play-state: ${({ playState }: any) => playState};
`;

type JoaoProps = {
  cry?: boolean;
  jump?: boolean;
  hit?: boolean;
  playState: string;
};

export default ({ playState, jump = false, cry = false, hit = false }: JoaoProps) => {
  if (cry) {
    return <JoaoCry />;
  }

  if (jump) {
    return <JoaoJump />;
  }

  if (hit) {
    return <JoaoHit />;
  }

  if (playState === 'paused') {
    return <JoaoStill />;
  }

  return <JoaoWalk playState={playState} />;
};
