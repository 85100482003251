import React, { Fragment } from 'react';
import styled from 'styled-components';

import creditEffect from '../assets/credit-effect.png';
import { AboutType } from './config';
import Button from '../display/Button';

const CreditImage: any = styled.div`
  text-align: center;
  height: 100%;

  //max-width: 320px;

  background: url(${({ img }: any) => img});
  background-size: cover;
  background-position: top;
`;

const CreditFrame = styled.div`
  position: relative;
  overflow: hidden;
  width: 90%;
  height: 180px;

  margin: 18px;
`;

const CreditEffect = styled.img`
  position: absolute;

  left: 0;
  top: 0;
`;

const CreditDescription = styled.div`
  box-sizing: border-box;
  padding: 0 16px 16px;

  color: #232323;
  width: 260px;

  @media only screen and (max-width: 800px) {
    width: 100%;
  }
`;

const CreditContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: row;

  background-color: rgba(255, 255, 255, 0.75);
  box-shadow: 0 0 10px 1px #808080;
  border-radius: 2px;

  width: 90%;
  z-index: 100;

  @media only screen and (max-width: 800px) {
    flex-direction: column;
  }
`;

const Background: any = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  background: url(${({ img }: any) => img});
  background-size: cover;
  background-position: top;

  top: 0;
  left: 0;

  z-index: 10;
`;

const CloseContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 15px 30px;
`;

const ControlButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  margin-right: 15px;
`;

const CloseContainerDesktop = styled(CloseContainer)`
  display: flex;
  @media only screen and (max-width: 800px) {
    display: none;
  }
`;

const ControlButtonsContainerDesktop = styled(ControlButtonsContainer)`
  display: flex;

  @media only screen and (max-width: 800px) {
    display: none;
  }
`;

const CreditDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

type CreditProps = AboutType & {
  next: () => any;
  previous: () => any;
  close: () => any;
  displayCreditIndex: number;
  creditsLastIndex: number;
};

export default ({
  originalImage,
  background,
  description,
  Extra,
  next,
  previous,
  close,
  displayCreditIndex,
  creditsLastIndex,
}: CreditProps) => (
  <Fragment>
    <CreditContainer>
      <CreditFrame>
        <CreditImage img={originalImage} />
        {false && <CreditEffect src={creditEffect} />}
      </CreditFrame>
      <CreditDescriptionContainer>
        <CloseContainerDesktop onClick={close}>
          <Button text="X" small={true} />
        </CloseContainerDesktop>
        <CreditDescription>{description}</CreditDescription>
        <ControlButtonsContainerDesktop>
          {displayCreditIndex > 0 ? <Button text="<" small={true} onClick={previous} /> : <div></div>}
          {displayCreditIndex < creditsLastIndex ? <Button text=">" small={true} onClick={next} /> : null}
        </ControlButtonsContainerDesktop>
      </CreditDescriptionContainer>
    </CreditContainer>
    <Background img={background}>{Extra && <Extra />}</Background>
  </Fragment>
);
