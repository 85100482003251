import React, { Fragment, useEffect, useState } from 'react';

import styled from 'styled-components';
import specialThings, { SpecialThingType } from './specialThings';
import { v4 as uuid } from 'uuid';
import SpecialThing from './SpecialThing';

const SpecialThingPosition = styled.div`
  position: absolute;
  bottom: 140px;

  z-index: 10;
`;

type SpecialThingsGroupProps = {
  specialThingsCollected: number[];
};

const SpecialThingsGroup = ({ specialThingsCollected }: SpecialThingsGroupProps) => {
  const [specialThingMap, setSpecialThingMap] = useState<any[]>([]);

  useEffect(() => {
    const specialThingMap = Array(specialThings.length).fill(false);
    for (const specialThingCollectedIndex of specialThingsCollected) {
      specialThingMap[specialThingCollectedIndex] = true;
    }
    setSpecialThingMap(specialThingMap);
  }, [specialThingsCollected]);

  return (
    <Fragment>
      {specialThings.map(({ position, type }: SpecialThingType, idx) => {
        if (specialThingMap[idx]) {
          return null;
        }
        return (
          <SpecialThingPosition key={uuid()} style={{ left: `${position}px` }}>
            <SpecialThing type={type} animate={true} />
          </SpecialThingPosition>
        );
      })}
    </Fragment>
  );
};

export default SpecialThingsGroup;
