import React from 'react';
import styled from 'styled-components';
import { Characters } from '../types';

import CharacterFrame from './CharacterFrame';
import getCopy from 'translations/getCopy';
import { trackUsage } from 'track';

const ChooseContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  z-index: 100;

  color: white;

  max-width: 400px;
`;

const Title = styled.div`
  font-size: 42px;
  text-align: center;

  text-shadow: 1px -1px 0 rgba(45, 45, 45, 0.6);
`;

const Options = styled.div`
  margin: 20px auto;
`;

type ChooseCharacterProps = {
  choose: (character: Characters) => void;
};

export default ({ choose }: ChooseCharacterProps) => {
  function chooseDebora(character: Characters) {
    trackUsage('chosen_debora');
    choose(character);
  }

  function chooseJoao(character: Characters) {
    trackUsage('chosen_joao');
    choose(character);
  }

  return (
    <ChooseContainer>
      <Title>{getCopy('display.chooseCharacter')}</Title>
      <Options>
        <CharacterFrame onClick={chooseDebora} character={Characters.DEBORA} />
        <CharacterFrame onClick={chooseJoao} character={Characters.JOAO} />
      </Options>
    </ChooseContainer>
  );
};
