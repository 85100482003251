const translationMap = {
  en: {
    'display.play': 'PLAY',
    'display.about': 'ABOUT',
    'display.jump': 'JUMP',
    'display.tapJump': 'TAP = JUMP',
    'display.collectRings': 'COLLECT THE WEDDING RINGS',
    'display.collectSpecialThings': 'COLLECT THE WEDDING RINGS, A TIE AND A BOUQUET FOR A SPECIAL MESSAGE IN THE END',
    'display.chooseCharacter': 'CHOOSE YOUR CHARACTER',
    'display.tryAgain': 'TRY AGAIN',
    'display.playAgain': 'PLAY AGAIN',
    'display.missedRings': 'OH NO! YOU MISSED THE WEDDING RINGS!',
    'display.missedSpecialThings': 'OH NO! YOU SEEM TO HAVE MISSED SOME ESSENTIAL THINGS!',
    'display.lost': 'OOPS! YOU SHOULD TRY AGAIN',

    'credits.contact': 'turn your life story into a game',
    'credits.joao': 'game design, art concept and code by',
    'credits.mugga': 'pixel art mastermind and design by',
    'credits.contactUs': 'contact us',

    'about.characters':
      'This is us doing what we do best! Our colourful outfits became an inspiration for the characters of this game.',
    'about.floripa':
      '"Floripa" lies on a gorgeous island of fishing villages, colonial architecture and 42 sand beaches.',
    'about.enemies': "Oysters, crabs and blow fish are some of Floripa's highlights of marine life.",
    'about.dunes': "Joaquina's sand dunes are beautiful formations of white sand, with a gorgeous view of the sea.",
    'about.ribeirao': 'Ribeirão da Ilha is a bucolic and picturesque neighborhood with a sunset like no other!',
    'about.casarao': 'Villa Casarão is a historic house whose view and magic inspired us to celebrate our day there.',

    date: '21st April, 2022',
    location: 'Florianópolis, Brazil',
  },
  pt: {
    'display.play': 'JOGAR',
    'display.about': 'SOBRE',
    'display.jump': 'PULAR',
    'display.tapJump': 'TOQUE PARA PULAR',
    'display.collectRings': 'PEGUE OS ANÉIS DO CASAMENTO',
    'display.collectSpecialThings': 'PEGUE OS ANÉIS, A GRAVATA E O BUQUÊ PARA UMA MENSAGEM ESPECIAl AO FIM DO JOGO',
    'display.chooseCharacter': 'ESCOLHA SEU PERSONAGEM',
    'display.tryAgain': 'TENTE DE NOVO',
    'display.playAgain': 'JOGUE DE NOVO',
    'display.missedRings': 'AH NÃO! VOCÊ ESQUECEU DE UM DOS ANÉIS!',
    'display.missedSpecialThings': 'AH NÃO! VOCÊ PARECE TER ESQUECIDO ALGUNS OBJETOS ESSENCIAIS',
    'display.lost': 'OPS! TENTE DE NOVO...',

    'credits.contact': 'transforme a sua história em um jogo',
    'credits.joao': 'game design, art concept and code by',
    'credits.mugga': 'pixel art mastermind and design by',
    'credits.contactUs': 'entre em contato',

    'about.characters':
      'Nós fazendo o que mais gostamos! Nossas roupas coloridas se tornaram uma inspiração para os personagens do jogo.',
    'about.floripa': 'Floripa, Ilha da Magia, capital de Santa Catarina, com arquitetura açoriana e 42 praias.',
    'about.enemies': 'A fauna marinha de Floripa conta com ostras, baiacus e a "Maria Farinha".',
    'about.dunes': 'As dunas da Joaquina são formações de areias brancas, com uma maravilhosa vista para o mar.',
    'about.ribeirao': 'Ribeirão da Ilha é um bairro açoriano e pitoresco com um pôr do sol como nenhum outro!',
    'about.casarao':
      'Villa Casarão é um casarão histórico cuja vista e magia nos inspiraram a celebrar o nosso dia lá.',

    date: '21 de Abril de 2022',
    location: 'Florianópolis, Brasil',
  },
};

export const browserLanguage = (() => {
  if (!window.navigator.language) {
    return 'en';
  }
  return window.navigator.language.split('-')[0];
})();

export default (key: string): string => {
  const translations = translationMap[browserLanguage] || translationMap['en'];
  return translations[key] || key;
};
