import React, { useState } from 'react';
import styled from 'styled-components';

import Credit from './AboutFrame';

import defaultCredits, { AboutType } from './config';
import Button from '../display/Button';

const CreditsContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: start;

  z-index: 100;
`;

const Credits = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: white;

  margin-top: 20px;
  width: 80%;

  @media only screen and (max-width: 800px) {
    width: 100%;
  }
`;

const CloseContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 auto 15px;

  width: 90%;
`;

const CloseContainerMobile = styled(CloseContainer)`
  display: none;

  @media only screen and (max-width: 800px) {
    display: flex;
  }
`;

const ControlButtonsContainer = styled.div`
  display: flex;
  width: 90%;
  justify-content: space-between;

  margin-top: 15px;
`;

const ControlButtonsContainerMobile = styled(ControlButtonsContainer)`
  display: none;

  @media only screen and (max-width: 800px) {
    display: flex;
  }
`;

type CreditsProps = {
  credits?: AboutType[];
  onClose?: () => any;
};

export default ({ credits = defaultCredits, onClose }: CreditsProps) => {
  const [displayCreditIndex, setDisplayCreditIndex] = useState(0);

  const next = () => setDisplayCreditIndex(displayCreditIndex + 1);
  const previous = () => setDisplayCreditIndex(displayCreditIndex - 1);
  const close = () => onClose && onClose();

  return (
    <CreditsContainer>
      <Credits>
        <CloseContainerMobile onClick={close}>
          <Button text="X" small={true} />
        </CloseContainerMobile>
        <Credit
          creditsLastIndex={credits ? credits.length - 1 : 0}
          displayCreditIndex={displayCreditIndex}
          next={next}
          previous={previous}
          close={close}
          {...credits[displayCreditIndex]}
        />
        <ControlButtonsContainerMobile>
          {displayCreditIndex > 0 ? <Button text="<" small={true} onClick={previous} /> : <div></div>}
          {credits && displayCreditIndex < credits.length - 1 ? <Button text=">" small={true} onClick={next} /> : null}
        </ControlButtonsContainerMobile>
      </Credits>
    </CreditsContainer>
  );
};
