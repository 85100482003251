import React from 'react';

import styled from 'styled-components';
import DefaultLayer from './DefaultLayer';
import EnemiesGroup from '../../enemies/EnemiesGroup';
import RingsGroup from '../../rings/RingsGroup';
import CharacterControl from '../../characters/CharacterControl';
import { Characters } from '../../types';
import SpecialThingsGroup from '../../rings/SpecialThingsGroup';

const CharacterLayer: any = styled(DefaultLayer)`
  height: 200px;

  bottom: 0;
  left: 0;

  z-index: 8;

  animation-play-state: ${({ playState }: any) => playState};
`;

type CharacterLayerProps = {
  playState: string;
  character?: Characters;
  updateLives: (lives: number) => void;
  updateRingsCollected: (ringsCollected: number[]) => void;
  updateSpecialThingsCollected: (specialThingsCollected: number[]) => void;
  markGameAsFinished: () => void;
  markRingsMissed: () => void;
  markSpecialThingsMissed: () => void;
  isSpecialGuest: boolean;
  ringsCollected: number[];
  specialThingsCollected: number[];
};

export default ({
  playState,
  character,
  updateLives,
  markGameAsFinished,
  markSpecialThingsMissed,
  markRingsMissed,
  updateRingsCollected,
  updateSpecialThingsCollected,
  ringsCollected,
  specialThingsCollected,
  isSpecialGuest,
}: CharacterLayerProps) => {
  return (
    <CharacterLayer playState={playState} id="character-layer">
      <CharacterControl
        playState={playState}
        character={character}
        updateLives={updateLives}
        updateRingsCollected={updateRingsCollected}
        updateSpecialThingsCollected={updateSpecialThingsCollected}
        markGameAsFinished={markGameAsFinished}
        markRingsMissed={markRingsMissed}
        markSpecialThingsMissed={markSpecialThingsMissed}
        isSpecialGuest={isSpecialGuest}
      />
      <EnemiesGroup playState={playState} />
      <RingsGroup ringsCollected={ringsCollected} />
      {isSpecialGuest && <SpecialThingsGroup specialThingsCollected={specialThingsCollected} />}
    </CharacterLayer>
  );
};
