import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useEvent } from '../hooks/useEvent';
import delay from '../utils/delay';

import Debora from './Debora';
import Joao from './Joao';

import CharacterEngine from '../engine/characterEngine';
import properties from '../engine/properties';
import { Characters, GameState } from '../types';
import { getCharacterPosition, getJumpingPosition } from '../utils/dom';

import shadow from '../assets/shadow.png';
import rings from '../rings/rings';
import specialThings from '../rings/specialThings';

const getFinishedAnimation = () => {
  return `
visibility: hidden;
opacity: 0;
transition: visibility 0s linear 300ms, opacity 400ms;
`;
};

const getEndAnimation = () => {
  return `right: ${properties.lengthInPixels - (properties.atEndOffset + properties.characterLeftOffset)}px;
  left: auto;
  transform: none;
  
animation: move-character-end ${properties.endAnimationDurationMs}ms linear;

@keyframes move-character-end {
  from {
    right: ${properties.lengthInPixels - (properties.rightBeforeEndOffset + properties.characterLeftOffset)}px;
  }
  to {
    right: ${properties.lengthInPixels - (properties.atEndOffset + properties.characterLeftOffset)}px;
  }
}`;
};

const Character: any = styled.div`
  position: absolute;
  display: block;

  left: ${properties.characterLeftOffset}px;

  bottom: 32px;
  z-index: 8;

  animation: move-character ${properties.speedInSeconds}s linear;
  transform: translateX(${properties.rightBeforeEndOffset}px);

  @keyframes move-character {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(${properties.rightBeforeEndOffset}px);
    }
  }

  ${({ isRightBeforeEnd }: any) => {
    return isRightBeforeEnd ? getEndAnimation() : '';
  }};

  animation-play-state: ${({ playState }: any) => playState};
`;

const JumpingContainer: any = styled.div`
  position: absolute;
  bottom: 0;
  transition: bottom ${properties.jumpReturnMs}ms ease-in-out;
  opacity: 1;

  ${({ finished }: any) => {
    return finished ? getFinishedAnimation() : '';
  }};
`;

const MovingShadow = styled.img`
  width: 44px;
  left: 25px;
  position: absolute;
  bottom: -3px;
  z-index: -1;
`;

const defaultState: GameState = {
  lives: 3,
  position: 70,
  jump: false,
  hit: false,
  ringTouched: false,
  ringsCollected: [],
  specialThingTouched: false,
  specialThingsCollected: [],
};

type CharacterControlProps = {
  playState: string;
  character?: Characters;
  updateLives: (lives: number) => void;
  updateRingsCollected: (ringsCollected: number[]) => void;
  updateSpecialThingsCollected: (specialThingsCollected: number[]) => void;
  markGameAsFinished: () => void;
  markRingsMissed: () => void;
  markSpecialThingsMissed: () => void;
  isSpecialGuest: boolean;
};

const CharacterControl: React.FC<CharacterControlProps> = ({
  playState,
  character,
  updateLives,
  updateRingsCollected,
  updateSpecialThingsCollected,
  markGameAsFinished,
  markRingsMissed,
  markSpecialThingsMissed,
  isSpecialGuest,
}: CharacterControlProps) => {
  const characterEngine = new CharacterEngine(defaultState);

  const [jump, updateJump] = useState<boolean>(false);
  const [hit, updateHit] = useState<boolean>(false);
  const [cry, updateCry] = useState<boolean>(false);
  const [isRightBeforeEnd, setIsRightBeforeEnd] = useState<boolean>(false);
  const [finished, setFinished] = useState<boolean>(false);

  const doJump = () => {
    if (isRightBeforeEnd || cry) {
      return;
    }

    const jumpingPosition = getJumpingPosition();
    if (!jump && jumpingPosition < 5) {
      updateJump(true);
      characterEngine.jump();
    }
  };

  const handleKeyPress = (e: Event) => {
    if ((e as any).key === ' ') {
      doJump();
    }
  };

  const handleTouch = () => {
    if (playState === 'paused') {
      return;
    }

    doJump();
  };

  useEvent('keyup', handleKeyPress);
  useEvent('touchstart', handleTouch);

  const checkMissedLastRing = (position: number, ringsCollected: number[]): boolean => {
    if (position >= rings[rings.length - 1].position + 70 && ringsCollected.length < rings.length) {
      markRingsMissed();
      updateCry(true);
      return true;
    }
    return false;
  };

  const checkMissedLastSpecialThing = (position: number, specialThingsCollected: number[]): boolean => {
    if (
      position >= specialThings[specialThings.length - 1].position + 70 &&
      specialThingsCollected.length < specialThings.length
    ) {
      markSpecialThingsMissed();
      updateCry(true);
      return true;
    }
    return false;
  };

  const checkEndGame = (position: number): boolean => {
    if (position >= properties.rightBeforeEndOffset + properties.characterLeftOffset) {
      setIsRightBeforeEnd(true);

      // reset jump and hit
      updateJump(false);
      updateHit(false);

      setTimeout(() => {
        setTimeout(() => {
          setFinished(true);
        }, 300);
        markGameAsFinished();
      }, properties.endAnimationDurationMs);

      return true;
    }
    return false;
  };

  useEffect(() => {
    if (playState === 'paused') {
      return;
    }

    async function tick(): Promise<void> {
      const position = getCharacterPosition();
      if (!position) {
        throw new Error('Not possible to retrieve position');
      }

      const state = characterEngine.repaint(position, isSpecialGuest);

      if (state.ringTouched) {
        updateRingsCollected(state.ringsCollected);
      }

      if (isSpecialGuest && state.specialThingTouched) {
        updateSpecialThingsCollected(state.specialThingsCollected);
      }

      if (!state.lives) {
        updateHit(state.hit);
        updateJump(false);

        setTimeout(() => {
          updateCry(true);
          updateLives(state.lives);
        }, 400);
      } else {
        updateLives(state.lives);
        updateJump(state.jump);
        updateHit(state.hit);
      }

      await delay();

      if (checkMissedLastRing(position, state.ringsCollected)) {
        return;
      }

      if (isSpecialGuest && checkMissedLastSpecialThing(position, state.specialThingsCollected)) {
        return;
      }

      if (checkEndGame(position)) {
        return;
      }

      await tick();
    }

    tick();
  }, [playState]);

  return (
    <Character id="character-control" playState={playState} isRightBeforeEnd={isRightBeforeEnd}>
      <JumpingContainer
        finished={finished}
        id="jumping-container"
        style={jump ? { bottom: `${properties.jumpHeightPx}px` } : {}}
      >
        {character ? (
          character === Characters.DEBORA ? (
            <Debora playState={playState} jump={jump} hit={hit} cry={cry} />
          ) : (
            <Joao playState={playState} jump={jump} hit={hit} cry={cry} />
          )
        ) : null}
      </JumpingContainer>
      {character && !finished && !jump ? <MovingShadow src={shadow} /> : null}
    </Character>
  );
};

export default CharacterControl;
