import React from 'react';

import properties from './properties';
import enemies from './enemies';
import styled from 'styled-components';

const GameDebug = styled.div`
  margin-top: 16px;

  border: 3px solid lightgray;
  padding: 16px;

  font-family: 'Courier New', sans-serif;
`;

export default () => (
  <GameDebug>
    <div>Total game width: {properties.lengthInPixels}px</div>
    <div>Total game time: {properties.speedInSeconds}s</div>
    <div>Number of enemies in course: {enemies.length}</div>
  </GameDebug>
);
