import React, { Fragment, useEffect, useState } from 'react';

import styled from 'styled-components';
import rings from './rings';
import { v4 as uuid } from 'uuid';
import Ring from './Ring';

const RingPosition = styled.div`
  position: absolute;
  bottom: 170px;

  z-index: 10;
`;

type RingsGroupProps = {
  ringsCollected: number[];
};

const RingsGroup = ({ ringsCollected }: RingsGroupProps) => {
  const [ringMap, setRingMap] = useState<any[]>([]);

  useEffect(() => {
    const ringMap = Array(rings.length).fill(false);
    for (const ringCollectedIndex of ringsCollected) {
      ringMap[ringCollectedIndex] = true;
    }
    setRingMap(ringMap);
  }, [ringsCollected]);

  return (
    <Fragment>
      {rings.map(({ position }: any, idx) => {
        if (ringMap[idx]) {
          return null;
        }
        return (
          <RingPosition key={uuid()} style={{ left: `${position}px` }}>
            <Ring />
          </RingPosition>
        );
      })}
    </Fragment>
  );
};

export default RingsGroup;
