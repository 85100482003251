import deboraSprites from '../assets/debora-sprites.png';
import joaoSprites from '../assets/joao-sprites.png';
import bridgeScenario from '../assets/bridge-scenario.png';
import deck from '../assets/deck.png';
import sun from '../assets/sun.png';
import wave from '../assets/wave.png';
import endAnimation from '../assets/end-animation.gif';
import ring from '../assets/ring.gif';

// display
import selectJoao from '../assets/select-joao.png';
import selectDebora from '../assets/select-debora.png';

// import '../Fixedsys.ttf';
import '../index.css';

// about
import bridgeOriginal from '../about/images/bridge-original.png';
import bridgeBackground from '../about/images/bridge-background.png';
import enemiesOriginal from '../about/images/enemies-original.png';
import dunesOriginal from '../about/images/dunes-original.png';
import dunesBackground from '../about/images/dunes-background.png';
import ribeiraoOriginal from '../about/images/ribeirao-original.png';
import ribeiraoBackground from '../about/images/ribeirao-background.png';
import casaraoOriginal from '../about/images/casarao-original.png';
import casaraoBackground from '../about/images/casarao-background.png';

// essential images to be preloaded
const allImages = [
  // scenario & characters
  deboraSprites,
  joaoSprites,
  bridgeScenario,
  deck,
  sun,
  wave,
  endAnimation,
  ring,

  // display
  selectDebora,
  selectJoao,

  // about
  bridgeOriginal,
  bridgeBackground,
  enemiesOriginal,
  dunesOriginal,
  dunesBackground,
  ribeiraoOriginal,
  ribeiraoBackground,
  casaraoOriginal,
  casaraoBackground,
  // 'https://res.cloudinary.com/dvvoecsqo/image/upload/q_auto:eco/v1587767255/about/characters-original_tprafd.png',
];

const loadImage = async (image: any) => {
  await new Promise(res => {
    const img = new Image();
    img.src = image;
    img.onload = () => {
      res();
    };
  });
};

export default async () => {
  try {
    await Promise.all(allImages.map(image => loadImage(image)));
  } catch (_) {
    // ignore if this fails for any reason
  }
};
