import React from 'react';
import styled from 'styled-components';

import keyboard from '../assets/keyboard.png';
import { useEvent } from '../hooks/useEvent';
import { isDesktop } from '../engine/properties';
import Button from './Button';
import Ring from '../rings/Ring';

import getCopy from '../translations/getCopy';
import SpecialThing from '../rings/SpecialThing';

const InitialInstructionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  z-index: 100;

  color: white;

  max-width: 400px;

  height: 100%;
  padding-top: 40px;
`;

const InstructionKeyboard = styled.div`
  height: 200px;
  width: 200px;

  background-image: url(${keyboard});
  background-size: contain;
  background-repeat: no-repeat;
`;

const InstructionText = styled.span`
  position: relative;
  font-size: 32px;
  font-weight: bold;

  color: #8f0076;

  letter-spacing: 1.5px;
  text-rendering: optimizeLegibility;
  width: 197px;
  text-align: center;
  margin-top: 16px;
  display: block;
`;

const RingInstruction = styled.div`
  font-size: 28px;
  text-shadow: 1px -1px 0 rgba(45, 45, 45, 0.6);

  margin-bottom: 12px;

  text-align: center;
`;

const RingContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  align-items: center;

  margin-bottom: 22px;

  @media only screen and (max-width: 800px) {
    width: 250px;
  }
`;

const Things = styled.div`
  display: flex;
  align-items: center;
`;

type InitialInstructionsProps = {
  start: () => void;
  isSpecialGuest: boolean;
};

export default ({ start, isSpecialGuest }: InitialInstructionsProps) => {
  const handleKeyPress = (e: Event) => {
    if ((e as any).key === ' ') {
      start();
    }
  };

  useEvent('keyup', handleKeyPress);

  return (
    <InitialInstructionContainer onClick={!isDesktop() ? start : () => {}}>
      <RingContainer>
        <RingInstruction>
          {isSpecialGuest ? getCopy('display.collectSpecialThings') : getCopy('display.collectRings')}
        </RingInstruction>
        <Things>
          <Ring small={true} />
          &nbsp;
          <Ring small={true} />
          &nbsp;
          {isSpecialGuest && <SpecialThing small={true} type="tie" animate={true} />}
          &nbsp;
          {isSpecialGuest && <SpecialThing small={true} type="flowers" animate={true} />}
        </Things>
      </RingContainer>
      {isDesktop() ? (
        <InstructionKeyboard>
          <InstructionText>{getCopy('display.jump')}</InstructionText>
        </InstructionKeyboard>
      ) : (
        <Button width="200px" onClick={start} text={getCopy('display.tapJump')} />
      )}
    </InitialInstructionContainer>
  );
};
