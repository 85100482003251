import React from 'react';
import styled from 'styled-components';
import getCopy from 'translations/getCopy';
import { trackUsage } from 'track';

const Info = styled.div`
  position: absolute;
  z-index: 100;

  bottom: 20px;
  left: 0;
  width: 100%;

  display: flex;
  justify-content: center;

  font-size: 14px;
  padding: 12px;
  box-sizing: border-box;

  color: white;

  a {
    color: white;
  }
`;

const Contact = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default () => (
  <Info>
    <Contact>
      <span>{getCopy('credits.contact')}</span>
      <a href="https://jvmartins.com/contact" onClick={() => trackUsage('clicked_contact')} target="_blank">
        {getCopy('credits.contactUs')}
      </a>
    </Contact>
  </Info>
);
