import React, { Fragment, useEffect, useState } from 'react';

import Scenario from './scenario/Scenario';
import GameDebug from './engine/GameDebug';
import { useEvent } from './hooks/useEvent';
import { isDebugEnabled, isSpecialGuestFromURL } from './utils/dom';

import { Characters } from './types';

import HealthStatus from './display/HealthStatus';
import ChooseCharacter from './display/ChooseCharacter';
import StartGame from './display/StartGame';
import InitialInstructions from './display/InitialInstructions';
import LosingState from './display/LosingState';
import About from './about/About';
import { isDesktop } from './engine/properties';
import styled from 'styled-components';
import EndGame from 'display/EndGame';
import InfoCredits from 'display/InfoCredits';
import preload from 'preload/preload';
import Loading from 'display/Loading';
import { trackUsage } from 'track';
import VideoFrame from './display/VideoFrame';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  flex-direction: column;
`;

const NoLandscape = styled.div`
  display: none;

  @media screen and (min-width: 320px) and (max-width: 800px) and (orientation: landscape) {
    display: block;
  }
`;

const Game: React.FC = () => {
  const [playState, setPlayState] = useState<string>('paused');

  const [gameStarted, setGameStarted] = useState<boolean>(false);
  const [gameFinished, setGameFinished] = useState<boolean>(false);
  const [gameLost, setGameLost] = useState<boolean>(false);

  const [character, setCharacter] = useState<Characters>();

  const [lives, setLives] = useState<number>(3);

  const [ringsCollected, updateRingsCollected] = useState<number[]>([]);
  const [ringsMissed, setRingsMissed] = useState<boolean>(false);

  const [displayAbout, setAboutOpen] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [specialThingsCollected, updateSpecialThingsCollected] = useState<number[]>([]);
  const [specialThingsMissed, setSpecialThingsMissed] = useState<boolean>(false);
  const [isSpecialGuest, setIsSpecialGuest] = useState<boolean>(false);

  const [isVideoFrame, setIsVideoFrame] = useState<boolean>(false);

  const handleKeyPress = (e: Event) => {
    if (!isDebugEnabled()) {
      return;
    }
    if ((e as any).keyCode === 13) {
      setPlayState(playState === 'running' ? 'paused' : 'running');
    }
  };
  useEvent('keyup', handleKeyPress);

  const markGameAsFinished = () => {
    setGameFinished(true);
  };

  const markRingsMissed = () => {
    setRingsMissed(true);
  };

  const markSpecialThingsMissed = () => {
    setSpecialThingsMissed(true);
  };

  const triggerStart = () => {
    setGameStarted(true);
  };

  const restartGame = () => {
    window.location.reload();
  };

  const chooseCharacterAndStartGame = (character: Characters) => {
    setCharacter(character);
  };

  const startGame = () => {
    setPlayState('running');
  };

  // first load
  useEffect(() => {
    async function preloadImages() {
      await preload();

      setIsLoading(false);
    }

    setIsSpecialGuest(isSpecialGuestFromURL());
    preloadImages();
  }, []);

  // checks lives
  useEffect(() => {
    if (!lives) {
      trackUsage('lost_not_enough_lives');
      setGameLost(true);
      setPlayState('paused');
    }
  }, [lives]);

  // checks rings missed
  useEffect(() => {
    if (ringsMissed) {
      trackUsage('lost_rings_missed');
      setGameLost(true);
      setPlayState('paused');
    }
  }, [ringsMissed]);

  useEffect(() => {
    if (specialThingsMissed) {
      trackUsage('special_things_missed');
      setGameLost(true);
      setPlayState('paused');
    }
  }, [specialThingsMissed]);

  useEffect(() => {
    if (gameFinished && isSpecialGuest) {
      setTimeout(() => {
        setIsVideoFrame(true);
      }, 2000);
    }
  }, [gameFinished]);

  const displayStartGame = playState === 'paused' && !gameStarted && !displayAbout;
  const displayChooseCharacter = playState === 'paused' && gameStarted && !character;
  const displayInitialInstructions = playState === 'paused' && character && !gameFinished && !gameLost;
  const displayHealthStatus = playState === 'running' && !gameFinished;
  const displayEndGame = gameFinished && !displayAbout;
  const displayLosingState = character && gameLost;

  return (
    <Container>
      {isLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <Scenario
            playState={playState}
            character={character}
            isGameFinished={gameFinished}
            ringsCollected={ringsCollected}
            updateRingsCollected={updateRingsCollected}
            markGameAsFinished={markGameAsFinished}
            markRingsMissed={markRingsMissed}
            updateLives={setLives}
            updateSpecialThingsCollected={updateSpecialThingsCollected}
            markSpecialThingsMissed={markSpecialThingsMissed}
            specialThingsCollected={specialThingsCollected}
            isSpecialGuest={isSpecialGuest}
          >
            {displayStartGame && <StartGame openCredits={() => setAboutOpen(true)} triggerStart={triggerStart} />}
            {displayChooseCharacter && <ChooseCharacter choose={chooseCharacterAndStartGame} />}
            {displayInitialInstructions && <InitialInstructions start={startGame} isSpecialGuest={isSpecialGuest} />}
            {displayHealthStatus && character && (
              <HealthStatus
                ringsCollected={ringsCollected}
                characterSelected={character}
                lives={lives}
                specialThingsCollected={specialThingsCollected}
              />
            )}
            {displayEndGame && <EndGame restart={restartGame} openCredits={() => setAboutOpen(true)} />}
            {displayLosingState && (
              <LosingState ringsMissed={ringsMissed} specialThingsMissed={specialThingsMissed} restart={restartGame} />
            )}
            {displayAbout && <About onClose={() => setAboutOpen(false)} />}
          </Scenario>
          {isDebugEnabled() && <GameDebug />}
          {isDesktop() && <InfoCredits />}
          {isVideoFrame && isSpecialGuest && <VideoFrame />}
          <NoLandscape>PORTRAIT ONLY</NoLandscape>
        </Fragment>
      )}
    </Container>
  );
};

export default Game;
