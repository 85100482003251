import React, { Fragment } from 'react';
import styled from 'styled-components';
import enemies from '../engine/enemies';
import { v4 as uuid } from 'uuid';

const Enemy = styled.div`
  position: absolute;
  bottom: 22px;

  z-index: 10;
`;

type EnemiesGroupProps = {
  playState: string;
};

const EnemiesGroup = ({ playState }: EnemiesGroupProps) => {
  const params = new URLSearchParams(window.location.search);
  const debug = !!params.get('debug');

  return (
    <Fragment>
      {enemies.map(({ Component, position }) => (
        <Enemy key={uuid()} style={{ left: `${position}px` }}>
          <Component playState={playState} />
          {debug && `${position}px`}
        </Enemy>
      ))}
    </Fragment>
  );
};

export default EnemiesGroup;
