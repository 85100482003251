import React from 'react';
import styled from 'styled-components';
import getCopy from 'translations/getCopy';
import { trackUsage } from 'track';

const Info = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  font-size: 12px;
  padding: 12px;
  box-sizing: border-box;

  color: #797979;

  a {
    color: #797979;
  }
`;

const InfoCredits = styled.div`
  text-align: right;
`;

export default () => (
  <Info>
    <div>
      <span>{getCopy('credits.contact')}</span>
      <br />
      <a href="https://jvmartins.com/contact" onClick={() => trackUsage('clicked_contact')} target="_blank">
        {getCopy('credits.contactUs')}
      </a>
    </div>
    <InfoCredits>
      <div>
        {getCopy('credits.joao')}{' '}
        <a href="https://jvmartins.com" onClick={() => trackUsage('clicked_joao')} target="_blank">
          João
        </a>
      </div>
      <div>
        {getCopy('credits.mugga')}{' '}
        <a href="http://mugga.com.br" onClick={() => trackUsage('clicked_mugga')} target="_blank">
          Mugga
        </a>
      </div>
    </InfoCredits>
  </Info>
);
