export function isDebugEnabled(): boolean {
  const params = new URLSearchParams(window.location.search);
  return !!params.get('debug');
}

export function isRealGuest(): boolean {
  const params = new URLSearchParams(window.location.search);
  return !!params.get('guest') || isSpecialGuestFromURL();
}

const specialGuests = {
  'elisaegustavo-ffaffc93': 'https://www.youtube.com/embed/p3kgXD9JdJU',
  'gabieed-2317c7ad': 'https://www.youtube.com/embed/Od1c4lJG2Qk',
  'helenaejoinva-3313c864': 'https://www.youtube.com/embed/LtlohVgMphE',
  'mairaejulian-1c7df2bb': 'https://www.youtube.com/embed/cKmyRpq0fE8',
  'licaeed-fda167d4': 'https://www.youtube.com/embed/EFiCRIk9dXc',
  'laura-ad424688': 'https://www.youtube.com/embed/Q6xbn1J590A',
  'gabriel-568263b2': 'https://www.youtube.com/embed/sssTSLybNcA',
  'marieevandro-046f06df': 'https://www.youtube.com/embed/qYRuziA7rnY',
  'biaerafa-9c7e987f': 'https://www.youtube.com/embed/ZJfZdIJCHC4',
};

export function getSpecialGuest(): string | null {
  const params = new URLSearchParams(window.location.search);
  return specialGuests[params.get('specialGuest') || ''];
}

export function isSpecialGuestFromURL(): boolean {
  const params = new URLSearchParams(window.location.search);
  const specialGuestParam = params.get('specialGuest');
  return specialGuestParam ? !!specialGuests[specialGuestParam] : false;
}

export function getCharacterPosition(): number | void {
  const currentCharacterRef = document.getElementById('character-control');
  const parentLayer = document.getElementById('character-layer');
  if (currentCharacterRef && parentLayer) {
    const parentPos = parentLayer.getBoundingClientRect();
    const childPos = currentCharacterRef.getBoundingClientRect();
    return childPos.left - parentPos.left;
  }
}

export function getJumpingPosition(): number | void {
  const jumpingRef = document.getElementById('jumping-container');
  const parentLayer = document.getElementById('character-control');
  if (jumpingRef && parentLayer) {
    const parentPos = parentLayer.getBoundingClientRect();
    const childPos = jumpingRef.getBoundingClientRect();
    return parentPos.bottom - childPos.bottom;
  }
}
