const windowWidth = window.screen.availWidth;

export const isDesktop = () => windowWidth > 800;

const offset = isDesktop() ? 800 : windowWidth + 50;
const offsetEnding = isDesktop() ? 530 : windowWidth - 60;

const lengthInPixels = 7000;
export default {
  lengthInPixels,
  speedInSeconds: 35,
  spritesSizeSquarePx: 128,

  rightBeforeEndOffset: lengthInPixels - offset,
  atEndOffset: lengthInPixels - offsetEnding,

  repaintMs: 90,

  characterLeftOffset: isDesktop() ? 250 : 25,

  endAnimationDurationMs: 2000,

  jumpSpeedMs: 550,
  jumpReturnMs: 600,
  jumpHeightPx: 90,
};
