import React from 'react';

import ring from '../assets/ring.gif';
import styled from 'styled-components';

const Ring: any = styled.img`
  width: ${({ small }: any) => (small ? '22' : '38')}px;
  margin-right: 3px;
`;

type RingProps = {
  small?: boolean;
};

export default ({ small = false }: RingProps) => <Ring small={small} src={ring} />;
