import styled from 'styled-components';
import enemiesSprite from '../assets/enemies-sprites.png';
import properties from '../engine/properties';

const baseSpritesSizePx = 32;

const Oyster = styled.div`
  width: ${baseSpritesSizePx * 3}px;
  height: ${baseSpritesSizePx * 3}px;

  background: url(${enemiesSprite});
  background-size: ${properties.spritesSizeSquarePx * 3}px ${(properties.spritesSizeSquarePx / 2) * 3}px;

  animation: animate-oyster steps(2) 750ms infinite;
  @keyframes animate-oyster {
    from {
      background-position: ${baseSpritesSizePx * 3}px 0;
    }
    to {
      background-position: ${baseSpritesSizePx * 9}px 0;
    }
  }

  animation-play-state: ${({ playState }: any) => playState};
`;

export default Oyster;
