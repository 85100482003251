import React from 'react';

import Sky from './layers/Sky';
import ScenesLayer from './layers/ScenesLayer';
import styled from 'styled-components';
import Ground from './layers/Ground';
import CharacterLayer from './layers/CharacterLayer';
import { Characters } from '../types';
import SlowScenesLayer from './layers/SlowScenesLayer';
import SeaLayer from './layers/SeaLayer';

const ScenarioContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  flex-direction: column;

  width: 800px;
  height: 500px;

  border: 5px solid #ffcc65;
  border-radius: 10px;

  background-color: var(--sky);
  overflow: hidden;

  box-shadow: 0 0 10px 1px #aaaaaa;

  @media only screen and (max-width: 800px) {
    border: none;
    border-radius: unset;
    box-shadow: none;
    margin-top: 0;
    width: 100%;
    height: 100%;
  }

  @media screen and (min-width: 320px) and (max-width: 800px) and (orientation: landscape) {
    display: none;
  }
`;

type ScenarioProps = {
  children?: any;
  character?: Characters;
  updateLives: (lives: number) => void;
  updateRingsCollected: (ringsCollected: number[]) => void;
  updateSpecialThingsCollected: (specialThingsCollected: number[]) => void;
  isGameFinished: boolean;
  markGameAsFinished: () => void;
  markRingsMissed: () => void;
  markSpecialThingsMissed: () => void;
  playState: string;
  ringsCollected: number[];
  specialThingsCollected: number[];
  isSpecialGuest: boolean;
};

const ScenarioControl = ({
  children,
  playState,
  character,
  updateLives,
  isGameFinished,
  ringsCollected,
  specialThingsCollected,
  updateRingsCollected,
  updateSpecialThingsCollected,
  markRingsMissed,
  markSpecialThingsMissed,
  markGameAsFinished,
  isSpecialGuest,
}: ScenarioProps) => {
  return (
    <ScenarioContainer id="scenario">
      {children}
      <Ground playState={playState} />
      <CharacterLayer
        character={character}
        playState={playState}
        updateLives={updateLives}
        ringsCollected={ringsCollected}
        specialThingsCollected={specialThingsCollected}
        updateRingsCollected={updateRingsCollected}
        updateSpecialThingsCollected={updateSpecialThingsCollected}
        markGameAsFinished={markGameAsFinished}
        markRingsMissed={markRingsMissed}
        markSpecialThingsMissed={markSpecialThingsMissed}
        isSpecialGuest={isSpecialGuest}
      />
      <Sky playState={playState} />
      <ScenesLayer playState={playState} isGameFinished={isGameFinished} />
      <SeaLayer playState={playState} />
      <SlowScenesLayer playState={playState} />
    </ScenarioContainer>
  );
};

export default ScenarioControl;
