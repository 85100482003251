import React from 'react';
import styled from 'styled-components';
import { Characters } from '../types';
import CharacterFrame from './CharacterFrame';

const StatusBar = styled.div`
  position: absolute;
  left: 0;
  top: 0;

  padding: 25px;
  z-index: 100;
`;

type HealthStatusProps = {
  characterSelected: Characters;
  lives?: number;
  ringsCollected?: number[];
  specialThingsCollected?: number[];
};

export default ({ characterSelected, lives, ringsCollected, specialThingsCollected }: HealthStatusProps) => (
  <StatusBar>
    <CharacterFrame
      character={characterSelected}
      lives={lives}
      ringsCollected={ringsCollected}
      specialThingsCollected={specialThingsCollected}
    />
  </StatusBar>
);
