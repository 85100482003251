import React from 'react';
import styled from 'styled-components';

import buttonLeft from '../assets/button-left.png';
import buttonMiddle from '../assets/button-middle.png';
import buttonRight from '../assets/button-right.png';

const BUTTON_HEIGHT = 70;

const Button: any = styled.div`
  display: flex;

  z-index: 100;

  font-size: 30px;
  font-weight: bold;
  cursor: pointer;

  ${({ small }: any) => (small ? `font-size: 16px` : '')}
  ${({ medium }: any) => (medium ? `font-size: 20px` : '')}
`;

const ButtonPart: any = styled.img`
  height: ${BUTTON_HEIGHT}px;
  ${({ small }: any) => (small ? `height: 35px` : '')}
  ${({ medium }: any) => (medium ? `height: 50px` : '')}
`;

const ButtonMiddle: any = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: ${BUTTON_HEIGHT}px;
  width: ${({ width }: any) => `${width}px` || 'auto'};

  background-size: contain;
  background-image: url(${buttonMiddle});

  ${({ small }: any) => (small ? `height: 35px` : '')}
  ${({ medium }: any) => (medium ? `height: 50px` : '')}
`;

const ButtonText = styled.span`
  margin: 4px 4px 6px;
  color: #8f0076;
  // text-shadow: 1px -1px 0 #b30094;
  // color: #b30094;
  // color: #e6e6e6;
  letter-spacing: 1.5px;
  text-rendering: optimizeLegibility;
`;

type ButtonProps = {
  text: string;
  width?: string;
  onClick?: () => void;
  small?: boolean;
  medium?: boolean;
};

export default ({ text, onClick, width, small = false, medium = false }: ButtonProps) => {
  return (
    <Button small={small} medium={medium} onClick={() => onClick && onClick()}>
      <ButtonPart small={small} medium={medium} src={buttonLeft} alt="button-left" />
      <ButtonMiddle small={small} medium={medium} width={width}>
        <ButtonText title={text}>{text}</ButtonText>
      </ButtonMiddle>
      <ButtonPart small={small} medium={medium} src={buttonRight} alt="button-right" />
    </Button>
  );
};
