import styled from 'styled-components';
import DefaultLayer from './DefaultLayer';

const GroundLayer: any = styled(DefaultLayer)`
  background-color: var(--beach);
  height: 200px;

  bottom: 0;
  left: 0;

  animation-play-state: ${({ playState }: any) => playState};
`;

export default GroundLayer;
