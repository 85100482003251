import React, { Fragment } from 'react';
import styled from 'styled-components';

import DefaultLayer from './DefaultLayer';

import properties from '../../engine/properties';
import cloud from '../../assets/cloud.png';
import sun from '../../assets/sun.png';

const Sun: any = styled.img`
  position: absolute;

  left: 290px;
  bottom: 163px;
  height: 70px;
  z-index: 4;

  animation: move-sun ${properties.speedInSeconds}s ease-in;
  @keyframes move-sun {
    from {
      left: 580px;
    }
    to {
      left: 290px;
    }
  }

  animation-play-state: ${({ playState }: any) => playState};

  @media only screen and (max-width: 800px) {
    left: 100px;

    animation: move-sun-mobile ${properties.speedInSeconds}s ease-in;
    @keyframes move-sun-mobile {
      from {
        left: 330px;
      }
      to {
        left: 100px;
      }
    }
  }
`;

const SkyLayer: any = styled(DefaultLayer)`
  height: 80%;
  bottom: 196px;

  background: rgb(255, 156, 78);
  background: linear-gradient(0deg, rgba(255, 156, 78, 1) 0%, rgba(179, 167, 130, 1) 15%, rgba(19, 189, 242, 1) 100%);

  animation-play-state: ${({ playState }: any) => playState};
`;

const Clouds: any = styled(DefaultLayer)`
  bottom: 320px;
  height: 55px;

  animation-duration: ${properties.speedInSeconds * 30}s;
  image-rendering: pixelated;

  background-repeat: repeat-x;
  background-image: url(${cloud});
  background-position: left;
  background-size: contain;
`;

type SkyProps = {
  playState: string;
};

export default ({ playState }: SkyProps) => (
  <Fragment>
    <SkyLayer playState={playState} />
    <Sun src={sun} playState={playState} />
    <Clouds playState={playState} />
  </Fragment>
);
