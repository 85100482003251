import React from 'react';

import tie from '../assets/tie.png';
import flowers from '../assets/flowers.png';
import styled from 'styled-components';

const SpecialThing: any = styled.img`
  width: ${({ small }: any) => (small ? '22' : '38')}px;
  margin-right: 3px;

  animation: ${({ animate }: any) => (animate ? 'bounce 1s infinite' : 'none')};

  @keyframes bounce {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-4px);
    }
    100% {
      transform: translateY(0);
    }
  }
`;

type SpecialThingProps = {
  small?: boolean;
  animate?: boolean;
  type: string;
};

const imagesMap = {
  flowers: flowers,
  tie: tie,
};

export default ({ small = false, animate = false, type }: SpecialThingProps) => {
  return <SpecialThing small={small} src={imagesMap[type]} animate={animate} />;
};
