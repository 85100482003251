import styled from 'styled-components';

import properties from '../../engine/properties';

export const SlowDefaultLayer = styled.div`
  position: absolute;
  align-self: start;
  width: ${properties.lengthInPixels}px;

  animation: move-slow ${properties.speedInSeconds * 1.25}s linear;
  transform: translateX(-${properties.rightBeforeEndOffset}px);
  image-rendering: pixelated;

  @keyframes move-slow {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-${properties.rightBeforeEndOffset}px);
    }
  }
`;

export default styled.div`
  position: absolute;
  align-self: start;
  width: ${properties.lengthInPixels}px;

  animation: move ${properties.speedInSeconds}s linear;
  transform: translateX(-${properties.rightBeforeEndOffset}px);
  image-rendering: pixelated;

  @keyframes move {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-${properties.rightBeforeEndOffset}px);
    }
  }
`;
