import React from 'react';

import charactersEnemiesBackground from './images/characters-enemies-background.png';

import bridgeOriginal from './images/bridge-original.png';
import bridgeBackground from './images/bridge-background.png';

import enemiesOriginal from './images/enemies-original.png';

import dunesOriginal from './images/dunes-original.png';
import dunesBackground from './images/dunes-background.png';

import ribeiraoOriginal from './images/ribeirao-original.png';
import ribeiraoBackground from './images/ribeirao-background.png';

import casaraoOriginal from './images/casarao-original.png';
import casaraoBackground from './images/casarao-background.png';

import Debora from '../characters/Debora';
import Joao from '../characters/Joao';
import styled from 'styled-components';
import Oyster from '../enemies/Oyster';
import MariaFarinha from '../enemies/MariaFarinha';
import BlowFish from '../enemies/BlowFish';
import getCopy from 'translations/getCopy';

export type AboutType = {
  originalImage: any;
  background: any;
  description: string;
  Extra?: () => any;
};

const CreditCharacterContainer = styled.div`
  position: absolute;
  bottom: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const CreditCharacterPosition = styled.div`
  margin-left: -50px;
`;

export default [
  {
    originalImage:
      'https://res.cloudinary.com/dvvoecsqo/image/upload/q_auto:eco/v1587767255/about/characters-original_tprafd.png',
    background: charactersEnemiesBackground,
    description: getCopy('about.characters'),
    Extra: () => (
      <CreditCharacterContainer>
        <Joao playState="paused" />
        <CreditCharacterPosition>
          <Debora playState="paused" />
        </CreditCharacterPosition>
      </CreditCharacterContainer>
    ),
  },
  {
    originalImage: bridgeOriginal,
    background: bridgeBackground,
    description: getCopy('about.floripa'),
  },

  {
    originalImage: enemiesOriginal,
    background: charactersEnemiesBackground,
    description: getCopy('about.enemies'),
    Extra: () => (
      <CreditCharacterContainer>
        <Oyster />
        <MariaFarinha />
        <BlowFish />
      </CreditCharacterContainer>
    ),
  },
  {
    originalImage: dunesOriginal,
    background: dunesBackground,
    description: getCopy('about.dunes'),
  },
  {
    originalImage: ribeiraoOriginal,
    background: ribeiraoBackground,
    description: getCopy('about.ribeirao'),
  },
  {
    originalImage: casaraoOriginal,
    background: casaraoBackground,
    description: getCopy('about.casarao'),
  },
] as AboutType[];
