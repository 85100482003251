import styled from 'styled-components';
import enemiesSprite from '../assets/enemies-sprites.png';
import properties from '../engine/properties';

const baseSpritesSizePx = 32;

const BlowFish = styled.div`
  width: ${baseSpritesSizePx * 3}px;
  height: ${baseSpritesSizePx * 3}px;

  background: url(${enemiesSprite});
  background-size: ${properties.spritesSizeSquarePx * 3}px ${(properties.spritesSizeSquarePx / 2) * 3}px;

  animation: animate-blow-fish steps(2) 750ms infinite;
  @keyframes animate-blow-fish {
    from {
      background-position: 0 ${baseSpritesSizePx * 3}px;
    }
    to {
      background-position: -${baseSpritesSizePx * 6}px ${baseSpritesSizePx * 3}px;
    }
  }

  animation-play-state: ${({ playState }: any) => playState};
`;

export default BlowFish;
