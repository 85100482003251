import React, { Fragment } from 'react';
import styled from 'styled-components';

import title from '../assets/title.png';
import help from '../assets/help.png';
import Button from './Button';
import getCopy from 'translations/getCopy';
import { trackUsage } from 'track';
import { isDesktop } from '../engine/properties';
import InfoCreditsMobile from './InfoCreditsMobile';

const Title = styled.div`
  z-index: 100;
  position: relative;

  margin-left: 25px;

  height: 200px;
  width: 230px;

  background-image: url(${title});
  background-size: contain;
  background-repeat: no-repeat;
`;

const Help = styled.img`
  z-index: 100;
  height: 32px;

  right: -19px;
  position: absolute;

  animation: blink-animation 2s steps(6, start) infinite;
  bottom: 75px;

  @keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }
`;

const StartContainer = styled.div`
  z-index: 100;
  display: flex;
  flex-direction: column;

  height: 175px;
  margin-top: 5px;

  justify-content: space-evenly;
`;

type StartGameProps = {
  triggerStart: () => void;
  openCredits: () => void;
};

export default ({ triggerStart, openCredits }: StartGameProps) => (
  <Fragment>
    <Title>
      <Help src={help} />
    </Title>
    <StartContainer>
      <Button text={getCopy('display.play')} width="160" onClick={triggerStart} />
      <Button
        text={getCopy('display.about')}
        width="160"
        onClick={() => {
          trackUsage('clicked_about_start');
          openCredits();
        }}
      />
      {!isDesktop() && <InfoCreditsMobile />}
    </StartContainer>
  </Fragment>
);
