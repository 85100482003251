import React from 'react';

import styled from 'styled-components';
import DefaultLayer from './DefaultLayer';
import dimensions from '../../engine/properties';

import wave from '../../assets/wave.png';

const ScenesSeaLayer: any = styled(DefaultLayer)`
  background-color: #0044cc;
  height: 86px;
  bottom: 112px;

  left: 0;

  z-index: 3;
  animation-play-state: ${({ playState }: any) => playState};
`;

const WaveLayer: any = styled.div`
  position: absolute;
  bottom: -12px;
  left: 0;

  z-index: 1;
  width: ${dimensions.lengthInPixels}px;
  height: 52px;

  image-rendering: pixelated;

  background-repeat: repeat-x;
  background-image: url(${wave});
  background-size: contain;

  animation: wave 7s ease-in-out infinite;
  @keyframes wave {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(16px);
    }
    100% {
      transform: translateY(0);
    }
  }
`;

type ScenesLayerProps = {
  playState: string;
};

export default ({ playState }: ScenesLayerProps) => (
  <ScenesSeaLayer playState={playState}>
    <WaveLayer playState={playState} />
  </ScenesSeaLayer>
);
