import React from 'react';
import styled from 'styled-components';

import DefaultLayer from './DefaultLayer';
import deck from '../../assets/deck.png';
import dunes from '../../assets/dunes.png';
import ribeirao from '../../assets/ribeirao-v2.png';
import curtain from '../../assets/curtain.png';
import fakeSign from '../../assets/fake-sign.png';
import endAnimation from '../../assets/end-animation.gif';
import { isRealGuest } from '../../utils/dom';

const Deck = styled.img`
  position: absolute;
  left: 0;

  bottom: 0;
  height: 175px;
`;

const Dunes = styled.img`
  position: absolute;

  left: 2000px;
  bottom: 70px;

  height: 185px;

  z-index: 2;
`;

const RibeiraoLayer = styled.div`
  position: absolute;
  right: 0;
  bottom: -3px;
`;

const Ribeirao = styled.img`
  height: 410px;
`;

const WeddingSign = styled.img`
  position: absolute;

  height: 104px;
  right: 55px;
  bottom: 72px;
`;

const FakeSign = styled.img`
  position: absolute;
  height: 157px;
  right: 76px;
  bottom: 21px;
`;

const EndAnimation = styled.img`
  position: absolute;

  height: 250px;
  right: 60px;
  bottom: -9px;
`;

const ScenesLayer: any = styled(DefaultLayer)`
  left: 0;
  bottom: 0;

  z-index: 5;
  animation-play-state: ${({ playState }: any) => playState};
`;

type ScenesLayerProps = {
  playState: string;
  isGameFinished: boolean;
};

export default ({ playState, isGameFinished }: ScenesLayerProps) => (
  <ScenesLayer playState={playState}>
    <Deck src={deck} />
    <Dunes src={dunes} />
    <RibeiraoLayer>
      <Ribeirao src={ribeirao} />
      {isGameFinished ? <EndAnimation src={endAnimation} /> : <WeddingSign src={curtain} />}
      {!isRealGuest() && isGameFinished && <FakeSign src={fakeSign} />}
    </RibeiraoLayer>
  </ScenesLayer>
);
