import React from 'react';
import styled from 'styled-components';

import Button from './Button';
import getCopy, { browserLanguage } from 'translations/getCopy';

const RestartContainer = styled.div`
  z-index: 100;
  display: flex;
  flex-direction: column;

  height: 100%;
  padding: 60px 20px;

  max-width: 400px;

  justify-content: start;
  align-items: center;
`;

const Title = styled.div`
  color: white;
  font-size: 36px;
  text-align: center;

  text-shadow: 1px -1px 0 rgba(45, 45, 45, 0.6);

  margin-bottom: 30px;
`;

type StartGameProps = {
  restart: () => void;
  ringsMissed: boolean;
  specialThingsMissed: boolean;
};

export default ({ restart, ringsMissed, specialThingsMissed }: StartGameProps) => (
  <RestartContainer>
    {specialThingsMissed && <Title>{getCopy('display.missedSpecialThings')}</Title>}
    {ringsMissed && !specialThingsMissed && <Title>{getCopy('display.missedRings')}</Title>}
    {!ringsMissed && !specialThingsMissed && <Title>{getCopy('display.lost')}</Title>}
    <Button text={getCopy('display.tryAgain')} width={browserLanguage === 'pt' ? '235' : '200'} onClick={restart} />
  </RestartContainer>
);
