import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';

import Button from './Button';
import getCopy, { browserLanguage } from 'translations/getCopy';
import { trackUsage } from 'track';
import { isRealGuest } from '../utils/dom';
import { isDesktop } from '../engine/properties';

const EndContainer = styled.div`
  z-index: 100;
  display: flex;
  flex-direction: column;

  height: 100%;
  width: 100%;

  justify-content: start;

  position: absolute;
  left: 40px;
  top: 30px;

  @media only screen and (max-width: 800px) {
    position: relative;
    display: flex;
    align-items: center;

    left: unset;
    top: unset;
    width: auto;

    height: 100%;
    padding-top: 35px;
  }
`;

const ButtonContainer = styled.div`
  margin: 3px 0;
`;

const WinMessage = styled.div`
  font-size: 18px;
  text-shadow: 1px -1px 0 rgba(45, 45, 45, 0.6);
  color: white;

  line-height: 24px;
  margin: 17px 0;

  a {
    color: white;
    display: block;
  }

  @media only screen and (max-width: 800px) {
    position: relative;
    text-align: center;
    line-height: 32px;
  }
`;

type EndGameProps = {
  restart: () => void;
  openCredits: () => void;
};

const Contact = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  margin-bottom: 20px;
  text-shadow: 1px -1px 0 rgba(45, 45, 45, 0.6);
  font-size: 18px;

  a {
    color: white;
    text-shadow: 1px -1px 0 rgba(45, 45, 45, 0.6);
  }
`;

const isGuest = isRealGuest();

export default ({ restart, openCredits }: EndGameProps) => {
  useEffect(() => {
    trackUsage('reached_end');
  }, []);

  return (
    <Fragment>
      <EndContainer>
        {!isGuest && !isDesktop() && (
          <Contact>
            <span>{getCopy('credits.contact')}</span>
            <a href="https://jvmartins.com/contact" onClick={() => trackUsage('clicked_contact')} target="_blank">
              {getCopy('credits.contactUs')}
            </a>
          </Contact>
        )}
        <ButtonContainer>
          <Button
            text={getCopy('display.playAgain')}
            width={browserLanguage === 'pt' ? '180' : '160'}
            onClick={() => {
              trackUsage('clicked_play_again');
              restart();
            }}
            medium={true}
          />
        </ButtonContainer>
        <ButtonContainer>
          <Button
            text={getCopy('display.about')}
            width={browserLanguage === 'pt' ? '180' : '160'}
            onClick={() => {
              trackUsage('clicked_about_end');
              openCredits();
            }}
            medium={true}
          />
        </ButtonContainer>

        <WinMessage>
          {isGuest && <div>{getCopy('date')}</div>}
          {isGuest && (
            <a
              onClick={() => trackUsage('clicked_location')}
              href="https://goo.gl/maps/oudihzDwmTTT7k749"
              target="_blank"
            >
              Villa Casarão
            </a>
          )}
          <a>{getCopy('location')}</a>
        </WinMessage>
      </EndContainer>
    </Fragment>
  );
};
