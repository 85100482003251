import { GameState } from '../types';

import enemies from './enemies';
import properties from './properties';
import rings from '../rings/rings';
import specialThings from '../rings/specialThings';

import { getJumpingPosition } from '../utils/dom';

class CharacterEngine {
  private enemiesHitMap: any;
  private ringsHitMap: any;
  private specialThingsHitMap: any;

  constructor(public state: GameState) {
    this.enemiesHitMap = enemies.map(enemy => enemy.position);
    this.ringsHitMap = rings.map(ring => ring.position);
    this.specialThingsHitMap = specialThings.map(specialThing => specialThing.position);
  }

  checkEnemyHit() {
    if (this.state.jump) {
      return;
    }

    const jumpingPosition = getJumpingPosition();
    if (jumpingPosition > 50) {
      return;
    }

    const offsetEnemyStart = -50;
    const offsetEnemyEnd = 10;

    const enemyIndex = this.enemiesHitMap.findIndex(
      (enemyPosition: number) =>
        this.state.position > enemyPosition + offsetEnemyStart && this.state.position < enemyPosition + offsetEnemyEnd
    );

    if (enemyIndex > -1) {
      this.enemiesHitMap.splice(enemyIndex, 1);
      this.state.lives = this.state.lives - 1;
      this.state.hit = true;

      setTimeout(() => (this.state.hit = false), 300);
    }
  }

  checkRingCollected() {
    if (!this.state.jump || this.state.ringTouched) {
      return;
    }

    const jumpingPosition = getJumpingPosition();

    if (jumpingPosition < 50) {
      return;
    }

    const offsetRingStart = -80;
    const offsetRingEnd = 0;

    const ringIndex = this.ringsHitMap.findIndex(
      (ringPosition: number) =>
        this.state.position > ringPosition + offsetRingStart && this.state.position < ringPosition + offsetRingEnd
    );

    if (ringIndex > -1) {
      const dedupedRingsCollected = new Set([...this.state.ringsCollected]).add(ringIndex);
      this.state.ringsCollected = Array.from(dedupedRingsCollected);
      this.state.ringTouched = true;

      setTimeout(() => {
        this.state.ringTouched = false;
      }, 500);
    }
  }

  checkSpecialThingCollected() {
    if (!this.state.jump || this.state.specialThingTouched) {
      return;
    }

    const jumpingPosition = getJumpingPosition();

    if (jumpingPosition < 50) {
      return;
    }

    const offsetSpecialThingStart = -80;
    const offsetSpecialThingEnd = 0;

    const specialThingIndex = this.specialThingsHitMap.findIndex(
      (specialThingPosition: number) =>
        this.state.position > specialThingPosition + offsetSpecialThingStart &&
        this.state.position < specialThingPosition + offsetSpecialThingEnd
    );

    if (specialThingIndex > -1) {
      const dedupedSpecialThingsCollected = new Set([...this.state.specialThingsCollected]).add(specialThingIndex);
      this.state.specialThingsCollected = Array.from(dedupedSpecialThingsCollected);
      this.state.specialThingTouched = true;

      setTimeout(() => {
        this.state.specialThingTouched = false;
      }, 500);
    }
  }

  jump() {
    if (this.state.jump) {
      return;
    }

    this.state.jump = true;

    setTimeout(() => {
      this.state.jump = false;
    }, properties.jumpSpeedMs);
  }

  repaint(newPosition: number, isSpecialGuest: boolean = false): GameState {
    this.state.position = newPosition;

    this.checkEnemyHit();

    this.checkRingCollected();

    if (isSpecialGuest) {
      this.checkSpecialThingCollected();
    }

    return this.state;
  }
}

export default CharacterEngine;
