import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Loading = styled.div`
  font-size: 22px;
  font-family: 'Courier New', Courier, monospace;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0 16px;
`;

const Dots = styled.div`
  font-family: 'Fixedsys', 'Courier New', Courier, monospace;

  &:before {
    animation: dots 1s linear infinite;
    content: '.';
  }

  @keyframes dots {
    0% {
      content: '.';
    }
    40% {
      content: '..';
    }
    75% {
      content: '...';
    }
  }
`;

const messages = ['Loading game assets', 'Building Floripa', 'Setting up characters'];
export default () => {
  const [message, setMessage] = useState<string>('');

  useEffect(() => {
    setTimeout(() => {
      setMessage(messages[0]);
      setTimeout(() => {
        setMessage(messages[1]);
        setTimeout(() => {
          setMessage(messages[2]);
        }, 3000);
      }, 3000);
    }, 1000);
  }, []);

  return (
    <Loading>
      {message}
      <Dots />
    </Loading>
  );
};
