import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { getSpecialGuest } from '../utils/dom';
import { isDesktop } from '../engine/properties';

const VideoFrame = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(241, 241, 241, 0.7);
  opacity: 1;
  z-index: 200;
  animation: opaque 0.5s ease-in;

  @keyframes opaque {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const Countdown = styled.span`
  font-size: 102px;
`;

export default () => {
  const [countdown, setCountdown] = useState<number>(3);
  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown(count => (count > 0 ? count - 1 : 0));
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const link = getSpecialGuest();

  return (
    <Fragment>
      <VideoFrame>
        {countdown ? (
          <Countdown>{countdown}</Countdown>
        ) : (
          link && (
            <iframe
              width={isDesktop() ? '800' : '100%'}
              height={isDesktop() ? '500' : '200'}
              src={link}
              title="Mensagem especial"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          )
        )}
      </VideoFrame>
    </Fragment>
  );
};
