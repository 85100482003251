import React from 'react';
import styled from 'styled-components';
import deboraSprites from '../assets/debora-sprites.png';
import properties from '../engine/properties';

const DeboraDefault: any = styled.div`
  width: ${properties.spritesSizeSquarePx * 0.75}px;
  height: ${properties.spritesSizeSquarePx * 1.5}px;

  background: url(${deboraSprites});
  background-size: ${properties.spritesSizeSquarePx * 3}px ${properties.spritesSizeSquarePx * 3}px;
`;

const DeboraWalk: any = styled(DeboraDefault)`
  animation: animate-debora-walk steps(2) 400ms infinite;
  @keyframes animate-debora-walk {
    from {
      background-position: ${properties.spritesSizeSquarePx * 4.5}px 0;
    }
    to {
      background-position: ${properties.spritesSizeSquarePx * 6}px 0;
    }
  }

  animation-play-state: ${({ playState }: any) => playState};
`;

const DeboraCry = styled(DeboraDefault)`
  animation: animate-debora-cry steps(2) 700ms infinite;
  @keyframes animate-debora-cry {
    from {
      background-position: 0 -${properties.spritesSizeSquarePx * 1.5}px;
    }
    to {
      background-position: -${properties.spritesSizeSquarePx * 1.5}px -${properties.spritesSizeSquarePx * 1.5}px;
    }
  }
`;

const DeboraJump = styled(DeboraDefault)`
  background-position: ${properties.spritesSizeSquarePx * 0.75}px 0;
`;

const DeboraStill = styled(DeboraDefault)`
  background-position: 0 0;
`;

const DeboraHit = styled(DeboraDefault)`
  animation: animate-debora-hit steps(2) 200ms infinite;
  @keyframes animate-debora-hit {
    from {
      background-position: 0 -${properties.spritesSizeSquarePx * 1.5}px;
    }
    to {
      background-position: ${properties.spritesSizeSquarePx * 1.5}px -${properties.spritesSizeSquarePx * 1.5}px;
    }
  }

  animation-play-state: ${({ playState }: any) => playState};
`;

type DeboraProps = {
  jump?: boolean;
  cry?: boolean;
  hit?: boolean;
  playState: string;
};

export default ({ jump = false, cry = false, hit = false, playState }: DeboraProps) => {
  if (cry) {
    return <DeboraCry />;
  }
  if (jump) {
    return <DeboraJump />;
  }
  if (hit) {
    return <DeboraHit />;
  }
  if (playState === 'paused') {
    return <DeboraStill />;
  }
  return <DeboraWalk playState={playState} />;
};
