export type GameState = {
  jump: boolean;
  hit: boolean;
  lives: number;
  position: number;
  ringTouched: boolean;
  ringsCollected: number[];
  specialThingTouched: boolean;
  specialThingsCollected: number[];
};

export enum Characters {
  JOAO = 'joao',
  DEBORA = 'debora',
}
